/* global io */
'use strict';

angular.module('cpformplastApp')
  .factory('socket', function(socketFactory, Auth, appConfig) {

    var ioSocket = undefined;

    if (typeof io === 'undefined') {
      console.error('Socket connection failed');
      return {
        syncUpdates(){},
        unsyncUpdates(){}
      };
    } else {
      ioSocket = io(appConfig.api.url, {'query': 'token=' + Auth.getToken()});
      console.log('Socket connection worked');
    }

    var socket = socketFactory({ ioSocket });

    return {
      socket,

      /**
       * Register listeners to sync an array with updates on a model
       *
       * Takes the array we want to sync, the model name that socket updates are sent from,
       * and an optional callback function after new items are updated.
       *
       * @param {String} modelName
       * @param {Function} cb
       */
      syncUpdates(modelName, cb) {
        cb = cb || angular.noop;

        /**
         * Syncs item creation/updates on 'model:save'
         */
        socket.on(modelName + ':save', function () {
          cb();
        });

        /**
         * Syncs removed items on 'model:remove'
         */
        socket.on(modelName + ':remove', function () {
          cb();
        });
      },

      /**
       * Removes listeners for a models updates on the socket
       *
       * @param modelName
       */
      unsyncUpdates(modelName) {
        socket.removeAllListeners(modelName + ':save');
        socket.removeAllListeners(modelName + ':remove');
      }
    };
  });
